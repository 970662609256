<template>
  <div class="header" ref="header">
    <div class="header__content">
      <div class="header__left-wrapper">
        <router-link class="header__icon-wrapper" to="/">
          <img class="header__icon header__icon--desktop" src="@\assets\svgs\fmk-logo-column.svg" alt="Fairmögenskompass-Logo">
          <img class="header__icon header__icon--mobile" src="@\assets\svgs\fmk-logo-row.svg" alt="Fairmögenskompass-Logo">
        </router-link>
      </div>
      <div class="header__right-wrapper">
        <div class="header__upper-wrapper">
          <span class="header__buttons--mobile">
            <HeaderBarsButton @toggleMenu="toggleMenu"></HeaderBarsButton>
          </span>
        </div>
        <div class="header__lower-wrapper">
          <span class="header__pages-button-wrapper">
            <HeaderPagesButton :pages="pagesData.pages"></HeaderPagesButton>
          </span>
          <div class="header__contact-anchor-link">
            <img class="header__contact-anchor" src="@\assets\svgs\mail-anchor-icon.svg" alt="Kontakt" @click="scrollToContact" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import variables from '@/assets/variables.less';
import config from '@/../config.js';
import pagesData from '@/assets/pagesData.js';
import HeaderBarsButton from './HeaderBarsButton.vue';
import HeaderPagesButton from './HeaderPagesButton.vue';
HeaderPagesButton

export default {
  name: 'main-header',
  components: {
    HeaderBarsButton,
    HeaderPagesButton,
  },
  data() {
    return {
      variables: variables,
      domain: config.domain,
      pagesData,
    };
  },
  methods: {
    getHeaderHeight() {
      return this.$el.clientHeight;
    },
    toggleMenu() {
      this.$emit("toggleMenu");
    },
    scrollToSection(sectionId) {
      const targetSection = document.getElementById(sectionId);
      if (targetSection) {
        const headerHeight = this.getHeaderHeight();
        const targetOffset = targetSection.offsetTop - headerHeight;
        window.scrollTo({
          top: targetOffset,
          behavior: "smooth",
        });
      }
    },
    scrollToContact() {
      const contactSection = document.getElementById('contact-section');
      if (contactSection) {
        const headerHeight = this.getHeaderHeight();
        const contactOffset = contactSection.offsetTop - headerHeight;

        window.scrollTo({
          top: contactOffset,
          behavior: 'smooth',
        });
      }
    },
  },
  computed: {
    visibleSections() {
      const currentPath = this.$route.path;
      const currentPage = this.pagesData.pages.find((page) => page.path === currentPath);
      if (currentPage) {
        return currentPage.sections.filter((section) => section.navText);
      }
      return [];
    },
  },
};

</script>


<style scoped lang="less">
@import '@/assets/variables.less';
.header {
  display: flex;
  background-color: @fmk-darkslate;
  color: @fmk-white;
  width: 100%;
  justify-content: center;
  box-shadow: 0rem 0rem 1.5rem 0.15rem @fmk-shadow;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  padding-block: .5rem;

  &__content { // Legt nur die Weite maximale Bildschirmweite fest
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 2rem;
    padding-inline: .2rem;
    min-height: 2.75rem;
    
    @media (min-width: @breakpoint-small) {
      padding-inline: 1rem;
    }
    @media (min-width: @breakpoint-large) {
      min-height: 3rem;
    padding-inline: .5rem;
    min-height: 3.75rem;
      padding-inline: 16vw;
      flex-direction: row;
      justify-content: left;
      column-gap: 2rem;
    }
    @media (min-width: @breakpoint-medium) {
      padding-inline: 11vw;
    }

  }
  &__left-wrapper {
    display: flex;
    width: 100%;
    @media (min-width: @breakpoint-large) {
      width: min-content;
    }
  }
  &__right-wrapper {
    width: fit-content;
    display: flex;
    flex-direction: row;
    width: fit-content;
    row-gap: .6rem;
    @media (min-width: @breakpoint-large) {
      width: 100%;
    }
  }
  &__upper-wrapper {
    width: fit-content;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1rem;
    @media (min-width: @breakpoint-large) {
      width: 100%;
      display: none;
    }
  }
  &__lower-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 2vw;
    justify-content: space-between;
    @media (max-width: @breakpoint-large) {
      display: none;
    }
  }
  &__page-navigation {
    list-style: none;
    display: none;
      margin-bottom: 0.3rem;
      @media (min-width: @breakpoint-small) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      column-gap: .87rem;
      row-gap: .4rem;
      align-items: center;
      justify-content: space-between;
      @media (max-width: @breakpoint-large) {
      overflow: auto;
      display: none;
      
    }
    }
  }
  &__page-navigation-element {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    position: relative;
    font-size: @font-size-5;
    font-family: @fmk-text;
    font-weight: @fmk-text-weight;
    color: @fmk-light-mint;
    &:hover {
    &::after {
      content:"";
      position: absolute;
      height: @fmk-border-weight--thin;
      width: 100%;
      background-color: @fmk-light-mint;
      bottom: -2rem;
    }
  }
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // @media (max-width: @breakpoint-medium) {
    //   width: 100%;
    // }
    // @media (max-width: @breakpoint-extra-small) {
    //   width: 100%;
    // }
    // @media (min-width: @breakpoint-small) {
    //   max-width: 100%;
    // }
    // @media (min-width: @breakpoint-small) and (max-width: @breakpoint-medium) {
    //   max-width: 100%;
    //   // width: 45%;
    //   // min-width: 8rem;
    // }
    // @media (min-width: @breakpoint-medium) and (max-width: @breakpoint-large) {
    //   width: 40%;
    //   min-width: 12rem;
    // }
    // @media (min-width: @breakpoint-large) and (max-width: @breakpoint-extra-large) {
    //   width: 32%;
    //   min-width: 14rem;
    // }
    // @media (min-width: @breakpoint-extra-large) {
    //   width: 25%;
    //   min-width: 15rem;
    // }
  }
  &__icon {
    width: 8rem;
    height: 100%;
  }
  &__icon--mobile {
    width: 14rem;
    height: 100%;
    @media (min-width: @breakpoint-large) {
      display: none;
    }
  }
  &__icon--desktop {
    display: none;
    @media (min-width: @breakpoint-large) {
      display: unset;
    }
  }

  &__buttons--mobile {
    @media (min-width: @breakpoint-large) {
      display: none;
    }
  }
  &__contact-anchor {
    height: 2.5rem;
    width: 2.5rem;
  }
  &__pages-button-wrapper {
    display: flex;
    flex-direction: row;

  }
}

</style>