<template>
  <router-link 
    :to="page.path" 
    class="header-pages-button" 
    v-for="page in filteredPages" 
    :key="page.id"
    :class="{ 'header-pages-button--active-page': page.path === currentPath }">
    {{ page.label }}
  </router-link>
</template>
  
<script>
import variables from '@/assets/variables.less';

export default {
  data() {
    return {
      variables: variables,
      currentPath: this.$route.path
    };
  },
  props: {
    pages: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredPages() {
      // Nur Seiten filtern, die inHeader auf true gesetzt haben
      return this.pages.filter(page => page.inHeader !== false);
    },
  },
  watch: {
    '$route.path'(newPath) {
      this.currentPath = newPath;
    }
  },
  methods: {
    navigateToPage(path) {
      this.$router.push(path);
    },
  },
};
</script>

  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="less">
  @import '@/assets/variables.less';
  .header-pages-button {
    display: flex;
    height: 1.75rem;
    color: @fmk-dark-green;
    font-weight: @fmk-text-weight--bold;
    width: fit-content;
    border-radius: 50px;
    font-size: @font-size-4;
    position: relative;
    font-family: @fmk-text;
    align-items: flex-start;
    margin-bottom: 0.3rem;
    font-size: @font-size-5;
    padding-block: 0.4rem;
    padding-inline: .8rem;

    // outline: solid 0.0625rem @fmk-light-mint;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    @media (min-width: @breakpoint-large) {
      height: 100%;
      padding-block: 0.2rem;
      color: @fmk-white;
    }

    &:hover {    
      @media (min-width: @breakpoint-large) {
        color: @fmk-green;
        background-color: @fmk-light-mint !important;
        box-shadow: 0rem 0.01rem 0.1rem 0.001rem #0113128d;
      }
     }
     &--active-page {
     &::after {
      display: flex;
      content: "";
      position: absolute;
      background-color: @fmk-dark-green;
      height: @fmk-border-weight--thin;
      bottom: .2rem;
      width: calc(100% - .8rem);
      @media (min-width: @breakpoint-large) {
        bottom: 0.8rem;
        background-color: @fmk-light-mint;
        width: calc(100% - 1.5rem);
      }
     }
    }
  }
  
  </style>
  