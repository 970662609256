// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router';

// createApp(App).mount('#app')


// // main.js


// Vue.config.productionTip = false;

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/variables.less'

const app = createApp(App)
app.use(store);

// Use the router plugin
app.use(router);

// Mount the app to the element with the id 'app'
app.mount('#app');
