<!-- App.vue -->

<template>
  <Header ref="headerComponent" @toggleMenu="toggleMenu" />
  <BlurOverlay v-if="showOverlay" @closeMenu="closeMenu" />
  <div class="content" :style="{ marginTop: headerHeight + 'px' }">
    <!-- <PageBanner :bannerId="'banner-home'"></PageBanner> -->
    <div class="content__wrapper">
      <router-view></router-view>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from './components/structure/Header.vue';
import Footer from './components/structure/Footer.vue';
import BlurOverlay from "./components/structure/BlurOverlay.vue";

export default {
  name: 'App',
  components: {
  Header,
  Footer,
  BlurOverlay,
},
data() {
    return {
      headerHeight: 0,
      showOverlay: false,
    };
  },
  mounted() {
    this.getHeaderHeight();
    window.addEventListener('resize', this.getHeaderHeight);
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.getHeaderHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getHeaderHeight() {
      this.headerHeight = this.$refs.headerComponent.getHeaderHeight();
    },
    toggleMenu() {
      this.showOverlay = !this.showOverlay;
    },
    closeMenu() {
      this.showOverlay = false;
    },
    handleResize() {
      // Schließe das Menü bei Änderung der Bildschirmbreite
      this.showOverlay = false;
    }
  },
};
</script>

<style lang="less">
@import '@/assets/variables.less';
  #app {
    font-family: Montserrat, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    color: @fmk-black;
    margin-top: -1rem;
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: @fmk-light-mint;
    position: relative;
    z-index: 900;
    overflow: hidden;
    
   
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    align-items: center;
        width: 100%;
        min-height: 10rem;
        @media (min-height: @breakpoint-medium) {
          min-height: 15rem;
        }
        @media (min-height: @breakpoint-large) {
          min-height: 50rem;
        }
        
        
    &&::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin-top:-5.5rem;
        background-image: url('./assets/images/mobile-background-triangles.png');
        background-size: cover;
        background-repeat: repeat-y;
        /* Wiederhole das Bild vertikal */
        z-index: -1;  
    }
  }
}
</style>