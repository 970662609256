// Für Vuex eingerichtet
import { createStore } from 'vuex';

export default createStore({
  state: {
    headerHeight: 0,
  },
  mutations: {
    setHeaderHeight(state, height) {
      state.headerHeight = height;
    },
  },
  actions: {
    updateHeaderHeight({ commit }, height) {
      commit('setHeaderHeight', height);
    },
  },
  getters: {
    getHeaderHeight: (state) => state.headerHeight,
  },
});
