<template>
  <div class="header-button-bars">
    <button title="Menü" class="header-button-bars__button" @click="toggleMenu">
      <i class="fa-solid fa-bars header-button-bars__button-icon"></i>
    </button>
    <div v-if="isMenuOpen" class="header-button-bars__menu">
      <div class="header__lower-wrapper header__buttons">
        <HeaderPagesButton :pages="pagesData.pages"></HeaderPagesButton>
      </div>
    </div>
    <div v-if="isMenuOpen" class="overlay" @click="closeMenu"></div>
  </div>
</template>

  
  <script>
import '@fortawesome/fontawesome-free/css/all.css';
import HeaderPagesButton from './HeaderPagesButton.vue';
import variables from '@/assets/variables.less';
import config from '@/../config.js';
import pagesData from '@/assets/pagesData.js';

  export default {
    name: 'main-header',
    components: {
        HeaderPagesButton,
    },
    data () {
        return {
        variables: variables,
        domain: config.domain,
        pagesData,
        isMenuOpen: false
        };
    },
    methods: {
        getHeaderHeight() {
        return this.$el.clientHeight;
        },
        toggleMenu() {
      this.$emit("toggleMenu");
    }
    }
    };
  </script>
  
  <style scoped lang="less">
  @import '@/assets/variables.less';
  .header-button-bars {
    &__button {
        padding: 0;
        font-size: @font-size-large-3;
        background: none;
        border: 0;
        color: @fmk-white;
        display: flex;
    }
    &__menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
    }
    .overlay {
      position: fixed;
      z-index: 900;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(5px);
      pointer-events: none;
  }
}
  </style>