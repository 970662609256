<template>
  <div class="page-menue_blur" @click="closeMenu">
    <div class="page-menue">
      <div class="page-menue_box">
        <HeaderPagesButton :pages="pagesData.pages"></HeaderPagesButton>
      </div>
    </div>
  </div>
  
</template>

<script>
import HeaderPagesButton from './HeaderPagesButton.vue';
import pagesData from '@/assets/pagesData.js';

export default {
  data () {
    return {
      pagesData
    };
  },
  components: {
  HeaderPagesButton,
  },
  methods: {
    closeMenu() {
      this.$emit("closeMenu");
    }
  }
};
</script>

<style scoped lang="less">
@import '@/assets/variables.less';
.page-menue {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  position: absolute;
  top: 3.75rem;
  max-width: @max-screen-width;
  
&_box {
  display: flex;
  flex-direction: column;
  background-color: @fmk-white--light-transparent;
  padding-inline: 0.75rem;
  padding-block: 2rem;
  width: 100%;
  max-width: 730px;
  
  align-items: flex-start;
  box-shadow: 0rem 0rem 1.5rem 0.15rem @fmk-shadow;
  background-color: @fmk-light-mint--shadow;
  
  // outline: @fmk-border-weight solid @fmk-white;
  row-gap: 0.25rem;
  margin-bottom: 3rem; //lässt das Menü weiter oben anzeigen
//   &::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 0.06rem;
//   background-color: @fmk-white;
//   top: -1.5rem;
// }
//   &::after {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 0.06rem;
//   background-color: @fmk-white;
//   bottom: -1.5rem;
// }
}
&_blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: @fmk-blur-shadow;
  color: @fmk-black;
  backdrop-filter: blur(0.2rem);
  }
}

</style>