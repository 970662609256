<template>
  <div class="footer-social-media-button" v-for="socialMedia in socialMediaList" :key="socialMedia.id">
      <a :href="socialMedia.link" :title="socialMedia.name" target="_blank">
        <i :class="socialMedia['fa-classes']" class="footer-social-media-button__icon"></i>
      </a>
    </div>
</template>

<script>
import variables from '@/assets/variables.less';
import pagesData from '@/assets/pagesData.js';
import '@fortawesome/fontawesome-free/css/all.css';


export default {
  data() {
  return {
    variables: variables,
    socialMediaList: pagesData['social-media'],
  };
},
};
</script>

<style scoped lang="less">
@import '@/assets/variables.less';
.footer-social-media-button {
  display: flex;
  color: @fmk-white;
  padding: 0.3rem;
  border: none;
  align-items: center;
  justify-content: center;
  
  &__icon {
    text-decoration: none;
    color: white;
    font-size: 3rem;
  }
}

</style>
